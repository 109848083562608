<template>
  
    <banner :banner="banner" :caption="caption" :path="bannerPath"></banner>
    
    <section class="content-home border-bottom-red">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-8 col-sm-12">
                    <div class="row border-bottom-red">
                        <div class="col-2">
                            <div class="content-block">
                                <img class="icon-red img-left" src="../../assets/images/sekilas-tentang-w.svg" alt="icon">
                            </div>
                        </div>

                        <div class="col-10">
                            <div class="content-block">
                                <h3>Sekilas Tentang <span class="alternate">Bahtera Adhiguna</span></h3>
								<p>PT Pelayaran Bahtera Adhiguna (PT BAg) merupakan perusahaan pelayaran nasional yang bergerak pada jasa angkutan laut di Indonesia.</p>
								<p>Bisnis utama PT BAg adalah fokus moda transportasi batubara untuk pengamanan pasokan batubara ke PLTU milik PLN, anak perusahaan PLN & Perusahaan Listrik Swasta (IPP). Disamping itu pula ditunjang oleh usaha jasa keagenan kapal & memiliki anak perusahaan, yaitu PT Adhiguna Putera bergerak di jasa bongkar muat dari/ke kapal, jasa EMKL, jasa keagenan kapal, assist tug & Jetty Management serta memiliki 17 kantor cabang di Indonesia.</p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-2">
                            <div class="content-block">
                                <img class="icon-red img-left" src="../../assets/images/berita-terbaru-w.svg" alt="icon">
                            </div>
                        </div>

                        <div class="col-10">
                            <div class="content-block">
                                <h3>Berita <span class="alternate">Terbaru</span></h3>
                                <div class="row berita">
                                    <template v-for="data in dataBerita" :key="data.idberita">
                                        <div class="col-lg-6 col-sm-12" v-if="data.jenis.length > 0">
                                            <article class="berita-post">
                                                <div class="col-lg-4 col-sm-2 post-thumb">
                                                    <video class="img-fluid img-berita" controls>
														<source :src="coverPath + data.cover" alt="video berita">
													</video>
                                                </div>
                                                <div class="col-lg-8 col-sm-10 post-contents">
                                                    <div class="post-date"><span> <timeago :datetime="data.waktu"/> </span></div>
                                                    <div class="post-title"><h6><a @click="bacaBerita(data.idberita)"> {{ data.judul }} </a></h6></div>
                                                    <div class="post-exerpts">
                                                        <small class="text-justify" v-html="data.berita.substr(3, 100)"></small>
                                                    </div>
                                                    <div class="more mt-2">
                                                        <a> <router-link :to="'/berita/baca/' + data.idberita">baca selengkapnya</router-link></a>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>

                                        <div class="col-lg-6 col-sm-12" v-else>
                                            <article class="berita-post">
                                                <div class="col-lg-4 col-sm-2 post-thumb">
                                                    <img :src="coverPath + data.cover" alt="cover berita" class="img-fluid img-berita">
                                                </div>
                                                <div class="col-lg-8 col-sm-10 post-contents">
                                                    <div class="post-date"><span> <timeago :datetime="data.waktu"/> </span></div>
                                                    <div class="post-title"><h6><a @click="bacaBerita(data.idberita)">{{ data.judul }}</a></h6></div>
                                                    <div class="post-exerpts">
                                                        <small class="text-justify" v-html="data.berita.substr(3, 90)"></small>
                                                    </div>
                                                    <div class="more mt-2">
                                                        <a> <router-link :to="'/berita/baca/' + data.idberita">baca selengkapnya <i class="zmdi zmdi-caret-right-circle"></i></router-link></a>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-12 armada-home">
                    <div class="content-block pb-0">
                        <img src="../../assets/images/armada-kapal-w.svg" alt="icon" width="50" height="50">
						<span class="title-w">Armada Kapal</span>
						<p class="text-w mt-3">PT Pelayaran Bahtera Adhiguna saat ini memiliki, mengelola dan mengoperasikan beberapa kapal dan  sea train untuk mengangkut komoditi di Indonesia.</p>
                    </div>

                    <div class="row armada">
                        <template v-for="data in dataArmada" :key="data.keyid">
                            <div class="col-12 mt-3 mb-3">
                                <article class="armada-post">
                                    <div class="col-lg-4 col-sm-6 post-thumb pl-0">
                                        <a>
                                            <router-link :to="'/armada/detail/' + data.keyid">
                                                <img :src="armadaPath + data.gambar_armada" alt="gambar armada" class="img-armada">
                                            </router-link>
                                        </a>
                                    </div>
                                    <div class="col-lg-8 col-sm-6 post-contents pl-0">
                                        <div class="post-title">
                                            <h6><a> {{data.nama_armada}} </a></h6>
                                            <p v-html="data.deskripsi"></p>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </template>
                    </div>

                    <div class="row border-bottom-red">
                        <div class="content-block">
                            <div class="flex-container">
                                <a> <router-link to="/armada/selengkapnya">
                                    <img class="icon-red mb-2 img-armada-more" src="../../assets/images/armada-kapal-chevron.svg" alt="icon">
                                </router-link> </a>
                                <div class="ms-3 text-white">	
									Armada Lainnya ...
								</div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <label for="aduan" class="label text-white mb-3 mt-2">WHISTLE BLOWING SYSTEM</label>
                            <div class="mb-3">
                                <label class="form-label text-white">Email</label>
                                <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" v-model="email">
                                <small class="text-danger"> {{msg.email}} </small>
                            </div>
                            <div class="mb-3">
                                <select class="form-select" aria-label="Default select example" v-model="kategori">
                                    <option selected disabled>Kategori Pelanggaran</option>
                                    <option>Umum</option>
                                    <option>Gratifikasi</option>
                                </select>
                                <small class="text-danger"> {{msg.kategori}} </small>
                            </div>
                            <div class="mb-3">
                                <label class="form-label text-white">Aduan Anda</label>
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="aduan"></textarea>
                                <small class="text-danger"> {{msg.aduan}} </small>
                            </div>
                            <button type="button" class="btn btn-danger btn-sm mb-2" @click="tambahAduan">Kirim</button>

                            <div class="mt-3 mb-3">
								<p class="text-white"><i class="zmdi zmdi-email"></i> Email : wbs@bahteradhiguna.co.id</p>
								<p class="text-white"><i class="zmdi zmdi-whatsapp"></i> SMS/WhatsApp : 081223232217</p>
								<p class="text-white"><i class="zmdi zmdi-radio"></i> Fax : +62 21 6901450, 6902726</p>
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>

import Banner from '../banner/banner.vue'
import axios from 'axios'

export default {
    name: 'pageBeranda',
    components: {
        Banner,
    },
    data () {
        return {
            // bannerPath: 'http://localhost:8080/assets/banner/',
            bannerPath: 'https://api.plnbag.co.id/assets/banner/',
            banner: '',
            caption: '',
            dataBerita: [],
            // coverPath: 'http://localhost:8080/assets/berita/',
            coverPath: 'https://api.plnbag.co.id/assets/berita/',
            dataArmada: [],
            // armadaPath: 'http://localhost:8080/assets/armada/',
            armadaPath: 'https://api.plnbag.co.id/assets/armada/',
            email: '',
            kategori: 'Kategori Pelanggaran',
            aduan: '',
            msg: '',
        }
    },
    mounted () {
        this.loadBanner()
        this.loadBerita()
        this.loadArmada()
    },
    computed: {
        params () {
            return {
                email: this.email,
                kategori: this.kategori,
                aduan: this.aduan
            }
        }
    },
    methods: {
        loadBanner () {
            axios.get('beranda_banner')
                .then((res) => {
                    this.banner = res.data.data.banner
                    this.caption = res.data.data.caption_indo
                })
        },

        loadBerita () {
            axios.get('beranda_berita')
                .then((res) => {
                    this.dataBerita = res.data.data
                })
        },

        loadArmada () {
            axios.get('beranda_armada')
                .then((res) => {
                    this.dataArmada = res.data.data
                })
        },

        tambahAduan () {
            axios.post('beranda_aduan', this.params)
                .then((res) => {
                    let status = res.data.status
                    if(status === 200) {
                        this.msg = res.data.msg
                        this.$swal.fire({
                            icon: 'success',
                            title: this.msg
                        })
                        this.aduan = ''
                        this.email = ''
                        this.kategori = ''
                    } else {
                        this.msg = res.data.msg
                    }
                })
        }
    }
}
</script>

<style scoped>
    .content-home {
        position: relative;
    }

    .content-home .content-block {
        padding: 10px 0 0;
    }

    .content-home .content-block h3 {
        color: #0158a7;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .armada-home {
        background: #0158a7;
    }

    .content-home .content-block span.title-w {
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 10px;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block p.text-w {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .content-block p {
        color: #404041;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .image-block {
        position: absolute;
        right: 5%;
        bottom: 0;
    }

    @media (max-width: 767px) {
        .content-home .image-block {
            display: none;
        }
    }

    .border-bottom-red {
        border-bottom: 5px solid #ea2e23;
    }

    .icon-red {
        background-color: #ea2e23;
        border-radius: 5px;
        padding: 10px;
        float: right;
    }

    @media (max-width: 767px) {
        .icon-red {
            float: none;
        }
    }
   
    span.alternate {
        color: #ea2e23;
        font-weight: 800;
    }

    .img-left {
        width: 85px;
        height: auto;
    }

    @media screen and (max-width: 767px) {
        .img-left {
            width: 45px;
            height: auto;
        }
    }

    .berita .berita-post,
    .berita .berita-post-single {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        margin-bottom: 15px;
    }

    .berita .berita-post-alt {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        margin-bottom: 25px;
        padding-bottom: 20px;
        border-bottom: 2px solid red;
    }

    .berita .berita-post .post-thumb,
    .berita .berita-post-alt .post-thumb,
    .berita .berita-post-single .post-thumb {
        -ms-flex-preferred-size: 30%;
        flex-basis: 30%;
    }

    .berita .berita-post .post-thumb img {
        /*width: 100%;*/
        position: absolute;
        border-radius: 15px;
        /*clip: rect(0px, 100px, 100px, 0px);*/
    }

    .berita .berita-post-alt .post-thumb img {
        position: absolute;
        border-radius: 15px;
        border: 2px solid #ea2e23;
    }

    .berita .berita-post-single img.post-thumb {
        border-radius: 15px;
        margin-right:15px;
        float: left;
        height: 115px;
        width: 175px;
    }

    .berita .berita-post .post-contents,
    .berita .berita-post-alt .post-contents,
    .berita .berita-post-single .post-contents,
    .armada .armada-post .post-contents {
        /*-ms-flex-preferred-size: 50%;
            flex-basis: 50%;*/
        padding: 0px 10px;
        position: relative;
        /*border-right: 1px solid;
        border-bottom: 1px solid;
        border-color: #e5e5e5;*/
    }

    .berita .berita-post .post-contents .post-date span {
        font-weight: 600;
        position: relative;
        font-size: 13px;
        color: #ea2e23;
    }

    .berita .berita-post-alt .post-contents .post-date span {
        font-weight: 600;
        position: relative;
        font-size: 13px;
        color: #3a3a3a;
    }

    .berita .berita-post-single .post-contents span.post-date {
        font-weight: 600;
        position: relative;
        font-size: 15px;
        color: #ea2e23;
    }

    .berita .berita-post .post-contents .post-title h6,
    .berita .berita-post-alt .post-contents .post-title h6,
    .berita .berita-post-single .post-contents .post-title h6 {
        margin-bottom: 15px;
        /*margin-top: 5px;*/
        line-height: 15px;
    }

    .berita .berita-post .post-contents .post-title h6 a {
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        font-size: 13px;
        color: #0158a7;
        line-height: 5px;
    }

    .berita .berita-post-alt .post-contents .post-title h6 a {
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        font-size: 13px;
        color: #ea2e23;
        line-height: 5px;
    }

    .berita .berita-post-single .post-contents .post-title h6 a {
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        font-size: 21px;
        color: #ea2e23;
        line-height: 21px;
    }

    .berita .berita-post .post-contents .post-exerpts p,
    .berita .berita-post-alt .post-contents .post-exerpts p {
        font-size: 10px;
        line-height: 15px;
    }

    .berita .berita-post-single .post-contents .post-exerpts p {
        font-size: 15px;
        line-height: 18px;
    }

    .berita .berita-post .post-contents .date {
        margin-top: 17px;
        width: 65px;
        height: 65px;
        text-align: center;
        background: #ff6600;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .berita .berita-post .post-contents .date h4 {
        color: #fff;
        font-weight: 400;
    }

    .berita .berita-post .post-contents .date h4 span {
        display: block;
        font-size: 1rem;
        font-weight: 400;
    }

    .berita .berita-post .post-contents .more,
    .berita .berita-post-alt .post-contents .more,
    .berita .berita-post-single .post-contents .more {
        text-align: right;
        margin-top: -15px;
    }

    .berita .berita-post .post-contents .more a {
        font-family: "Montserrat", sans-serif;
        color: #ea2e23;
        font-size: 12px;
        margin-top: 5px;
    }

    .berita .berita-post-alt .post-contents .more a {
        font-family: "Montserrat", sans-serif;
        color: #0158a7;
        font-size: 12px;
    }

    .berita .berita-post-single .post-contents .more a {
        font-family: "Montserrat", sans-serif;
        color: #0158a7;
        font-size: 15px;
    }

    @media (max-width: 767px) {
        .berita .berita-post .post-contents,
        .berita .berita-post-alt .post-contents,
        .berita .berita-post-single .post-contents {
            padding-right: 0;
        }
    }

    .img-berita {
        width: 100px;
        height: auto;
    }

    @media screen and (max-width: 720px) {
        .img-berita {
            width: 50px;
            height: auto;
        }
    }

    .img-armada {
        width: 115px;
        height: auto;
    }

    @media screen and (max-width: 720px) {
        .img-armada {
            width: 75px;
            height: auto;
        }
    }

    .armada .armada-post {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
    }

    .armada .armada-post .post-thumb img {
        position: relative;
        border-radius: 15px;
        border: 2px solid #fff;
    }

    .berita .berita-post .post-contents,
    .berita .berita-post-alt .post-contents,
    .berita .berita-post-single .post-contents,
    .armada .armada-post .post-contents {
        /*-ms-flex-preferred-size: 50%;
            flex-basis: 50%;*/
        padding: 0px 10px;
        position: relative;
        /*border-right: 1px solid;
        border-bottom: 1px solid;
        border-color: #e5e5e5;*/
    }

    .armada .armada-post .post-contents .post-title h6 a {
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        font-size: 17px;
        color: #fff;
    }

    .armada .armada-post .post-contents p {
        font-weight: 200;
        position: relative;
        font-size: 17px;
        color: #fff;
        line-height: 20px;
    }

    .more-armada {
        display: inline;
        position: absolute;
        margin-left: 15px;
        bottom: 0;
        margin-bottom: 20px;
    }

    .flex-container {
        display: flex;
        justify-content:start;
        align-items: center;
        margin-left: 20px;
    }

    .img-armada-more {
        width: 85px;
        height: 85px;
    }

    @media screen and (max-width: 720px) {
        .img-armada-more {
            width: 45px;
            height: 45px;
        }
    }

</style>